import { Button, Dialog, DialogActions, DialogContent, DialogSurface, DialogTitle, Field, Input, MessageBar, Spinner, Text } from '@fluentui/react-components';
import React, { useState, useEffect } from 'react';
import { StripeCardElement, StripeCardElementOptions, loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import env from '../../env/env';
import Spacer from '../Spacer/Spacer';
import Guest_Order_Flow from '../../lib/GuestOrderFlow/GuestOrderFlow';

export interface GuestPayInvoiceDialog_Props {
  display: boolean;
  paymentError: boolean;
  onClose: (hasCompleted: boolean, pm_id?: string) => void;

  // this is only set as an nullable param to prevent render issues on legacy components...
  // but this is a breaking change, so we should probaly remove the old refreneces to this
  // component, since all new transactions will go through the guest order flow from now on
  // anyway... 
  accessCode?: string;
}

interface GuestPayInvoiceDialog_State {}

// Load Stripe outside of the component to avoid recreating the object on every render
const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

const GuestPayInvoiceDialog: React.FC<GuestPayInvoiceDialog_Props> = ({ display, onClose, paymentError, accessCode }) => {
  return (
    <Elements stripe={stripePromise}>
      <GuestPayInvoiceDialog_Component 
        display={display} 
        onClose={onClose} 
        paymentError={paymentError} 
        accessCode={accessCode}
      />
    </Elements>
  );
};

const GuestPayInvoiceDialog_Component: React.FC<GuestPayInvoiceDialog_Props> = ({ display, onClose, paymentError, accessCode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [nameOnCard, setNameOnCard] = useState('');
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [hasPaymentError, setHasPaymentError] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");

  useEffect(() => {
    const isComplete = nameOnCard.trim() !== '' && isCardComplete;
    setIsFormComplete(isComplete);
  }, [nameOnCard, isCardComplete]);


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    // Set loading state to true
    setLoading(true);
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded
      return;
    }
  
    const cardElement = elements.getElement(CardElement) as StripeCardElement;
  
    // Create a payment method first
    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
  
    if (paymentMethodError) {
      // console.error(paymentMethodError);
      setLoading(false);
      // You might want to show the error to the user here
      return;
    }

    // attempt to make payment...
    const result = await Guest_Order_Flow.pay_guest_order_flow(
      accessCode as string, 
      nameOnCard, 
      paymentMethod.id
    );

    // check if we need to trigger 3dSecure....
    if(!result?.is_success && result?.next_action_required) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(result?.pi_client_secret as string);

      if (error) {
          // Show error to your customer (e.g., insufficient funds)
          console.error("Payment confirmation error:", error);
          alert(error.message);
      } else {
          // Payment succeeded
          if (paymentIntent.status === 'succeeded') {
              const paymentIntentId = paymentIntent.id;
              
              // now we can finally confirm the order payment and configure subscription..
              const confirm3dsResult = await Guest_Order_Flow.confirm_3ds_order_flow(accessCode as string, paymentIntent.id);
              if(confirm3dsResult) {

                // fuck yes, we are done here...
                onClose(true);
              }
              else {
                setLoading(false);
                setHasPaymentError(true);
                setPaymentErrorMessage("Your payment could not be processed. Please contact customer support.");
              }
          
          } else {
              console.log("Payment not successful:", paymentIntent);
              setHasPaymentError(true);
              setPaymentErrorMessage("");
          }
      }
    }
    else if(result?.is_success) {
      // we are done here...
      onClose(true);
    }
    else {
      // there was an error processing this credit card payment...
      setHasPaymentError(true);
      setPaymentErrorMessage(result?.payment_error_reason as string);
    }
  
    // // Now confirm the payemnt intent, if next action is required...
    // if(stripeNextActionRequired) {
    //   const { error: confirmError, setupIntent } = await stripe.confirmCardSetup(stripeClientSecret as string, {
    //     payment_method: {
    //       card: cardElement,
    //       billing_details: {
    //         name: nameOnCard, // Use the name on the card from the input
    //       },
    //     },
    //   });
    
  
    //   if (confirmError) {
    //     console.error('Setup Intent confirmation failed:', confirmError);
    //     setLoading(false);
    //     setHasPaymentError(true);
    //     setPaymentErrorMessage((confirmError as any).message);
    //     // Handle error during payment confirmation (e.g., insufficient funds, failed 3D Secure)
    //     return;
    //   }
    
    //   // If the setupIntent requires 3D Secure, the next_action will be populated
    //   if (setupIntent.next_action) {
    //     // This means the Setup Intent requires additional actions, such as 3D Secure
    //     // The Stripe.js library will automatically handle this, no additional code needed
    //     console.log('3D Secure action required:', setupIntent.next_action);
        
    //   } else {
    //     // Setup succeeded, you can save the setupIntent.id or perform further actions
    //     console.log('Setup succeeded:', setupIntent);
    //     onClose(true, paymentMethod.id); // Close the dialog with success
    //   }
    
    //   // End loading state
    //   setLoading(false);

    // }
    // else {
    //   // since we have not been sent any stripe next action requirements, we will
    //   // simply 
    //   onClose(true, paymentMethod.id); // Close the dialog with success
    // }
  };

  const handleDialogClose = () => {
    onClose(false);
  };

  return (
    <>
      {/* <Dialog open={hasPaymentError}>
        <DialogSurface>
          <DialogTitle>Your payment failed</DialogTitle>
          <br />
          <DialogContent>
            We were unable to process this transaction. Your bank or card issuer
            returned the following error message - <b>{paymentErrorMessage}</b>

            <br />
            Please check your details, and try again.
          </DialogContent>

          <br />
          <DialogActions>
            <Button onClick={() => setHasPaymentError(false)}>
              Try Again
            </Button>
          </DialogActions>
        </DialogSurface>
      </Dialog> */}

      <Dialog open={hasPaymentError} >
        <DialogSurface>
          <DialogTitle>Payment declined</DialogTitle>
          <br />
          <DialogContent>
            <Text>We could not process your payment as your payment method was declined. Please check your payment method and try again. If you think there is an issue, please contact 121 Digital customer support. </Text>
            
            <br /><br />
            <span>Your bank / card issuer returned:</span><br />
            <b>{paymentErrorMessage}</b>

            <br /><br />
            <Button onClick={() => {
              setHasPaymentError(false);
              setLoading(false);
            }}>Close</Button>
          </DialogContent>
        </DialogSurface>
      </Dialog>

      <Dialog open={display}>
        <DialogSurface>
          <DialogTitle>Pay this invoice online</DialogTitle>
          <DialogContent>

            <Spacer size="25px" />
            {paymentError? (
                <>
                  <MessageBar intent='error'>
                    There was an issue processing this payment. Please try again, or try to use
                    an alternative payment method.
                  </MessageBar>
                  <Spacer size='15px' />
                </>
              ): (<></>)}

              <form onSubmit={handleSubmit}>
                <Field label='Name on card'>
                  <Input
                    disabled={loading}
                    value={nameOnCard}
                    size='large'
                    onChange={(e) => setNameOnCard(e.target.value)}
                    placeholder='Enter name on card'
                  />
                </Field>

                <Spacer size='15px' />

                <Field label='Card Details'>
                  <div style={{ border: '1px solid', padding: 15, borderRadius: '4px' }}>
                    <CardElement
                      onChange={(event) => {
                        setIsCardComplete(event.complete);
                      }}
                      options={{
                        disabled: loading,
                        hidePostalCode: true
                      } as StripeCardElementOptions}
                    />
                  </div>
                </Field>
                
                <Spacer size="15px" />
                      
                {loading? (
                  <div style={{paddingTop: 25 ,paddingBottom: 25}}>
                    <Spinner size='large' labelPosition='below' label="Processing Payment" />
                    <p style={{textAlign: 'center', margin: 0, padding: 0}}>Please do not reload the page.</p>
                  </div>
                ) : (
                  
                  <>
                    <span>
                    Once you have confirmed your credit / debit card details, you agree for us to charge
                    you the full balance of the invoice. Using this service is subject to our terms of
                    service and privacy policy.
                  </span>

                  <Spacer size="15px" />
                  <span>
                    You are checking out as a guest, so your billing details will not be saved after this transaction.
                    Our payments are processed by Stripe. You can find their privacy policy published on their website 
                    <a style={{marginLeft: 2.5}} href='https://stripe.com/gb/privacy'>https://stripe.com/gb/privacy</a>.
                  </span>
                </>
                )}

                <Spacer size='25px' />
                <Button
                  disabled={!isFormComplete || loading}
                  type='submit'
                  appearance='primary'
                >
                  {loading ? <Spinner size='tiny' label='Processing...' /> : 'Pay invoice'}
                </Button>
                <Button disabled={loading} onClick={handleDialogClose} style={{ marginLeft: 15 }}>
                  Cancel
                </Button>
              </form>
          </DialogContent>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default GuestPayInvoiceDialog;
