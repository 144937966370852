import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import backgroundImage from './../../assets/backgrounds/default.jpg';
import { ReactComponent as BrandLogoSvg } from './../../assets/branding/brand.svg';
import { Button, Dialog, DialogSurface, DialogTitle, DialogContent, MessageBar, MessageBarActions, MessageBarBody, Table, TableCell, TableHeader, TableRow, Text, Spinner } from '@fluentui/react-components';
import { ArrowRight16Regular, ArrowRight20Regular } from '@fluentui/react-icons';
import Spacer from '../../components/Spacer/Spacer';
import GuestPayInvoiceDialog from '../../components/Guest/GuestPayInvoiceDialog';
import GuestPayInvoiceInvalid from '../../components/Guest/GuestPayInvoiceInvalid/GuestPayInvoiceInvalid'; // Corrected typo
import Guest_Order_Flow from '../../lib/GuestOrderFlow/GuestOrderFlow';
import env from '../../env/env';

export default class GuestWebsiteServiceStartView extends React.Component {

  private styles: any = {};
  private access_code: string = "";

  public constructor(props: any) {
    super(props);

    // define default state...
    this.state = {
      access_code_valid: false,
      display_payment_dialog: false,
      is_mobile: this.is_mobile_device(),

      payment_is_complete: false,
      payment_is_error: false,
      payment_is_processing: false,

      product_details: {},
      billing_details: {},

      stripe_client_secret: null,
      stripe_next_action_required: false,
      invoice_is_loading: false
    };

    // default styles init...
    this.styles = {};

    this.handle_screen_resize();

    // Listen for changes in viewport size
    window.addEventListener('resize', () => {
      window.setTimeout(() => {
        this.handle_screen_resize();
      }, 200)
    });
  }

  public generate_styles(): void {

    // update parts for mobile...
    const state = this.state as any;
    console.log(state);

    if(state.is_mobile) {
      this.styles = {
        wrapper: {
          width: '100vw',
          // height: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          justifyContent: 'flex-end',
          zIndex: 999999
        },

        top_pay_button: {
          // float: "right",
          marginRight: 15
        },

        invoice_details: {
          display: "none", 
          justifyContent: "space-between"
        },
  
        invoice_view: {
          width: "100%",
          minHeight: 650,
          backgroundColor: "white",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 0,
          borderRadius: 0
        },
  
        header: {
          paddingTop: 25,
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20
        },
        logo: {
          marginLeft: -320,
          marginTop: 15
        },
  
        invoice_header: {
          paddingTop: 25,
          paddingLeft: 25,
          paddingRight: 25
        },

        header_buttons: {
          marginTop: 25,
          display: "none", // do not display on mobile,
          justifyContent: "center", // Centers horizontally
        },

        table: {
          marginLeft: -7
        },

        table_description_cell: {
          display: "none"
        },

        table_item_cell: {
          width: "50"
        },

        totals_table: { 
          width: 200, 
          float: "right", 
          paddingRight:0 
        }
      };
    }
    else {
      const screenWidth = window.innerWidth;

      this.styles = {
        wrapper: {
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          display: 'flex',                    // Flexbox to center the content
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 999999,
          padding: '20px', 
          paddingRight: 50,                // Default padding for responsiveness
          boxSizing: 'border-box',
        },

        invoice_view: {
          maxWidth: 1000,
          width: '100%',                      // Set width to 100% by default
          // maxHeight: 'calc(100vh - 200px)',    // To ensure it fits well on screen
          minHeight: 650,
          backgroundColor: "white",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: 4,
          overflowY: 'auto',                  // Allow scrolling if content exceeds height
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',  // Add some depth
        },

        invoice_details: {
          display: "flex", 
          justifyContent: "space-between",
          paddingBottom: 50
        },

        header: {
          paddingTop: 25,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20
        },

        header_buttons: {
          width: "50%",
          
        },

        top_pay_button: {
          float: "right",
        },
        
        logo: {
          // float: "left"
          marginLeft: -340
        },
        logo_wrapper: {
          width: "50%"
        },

        invoice_header: {
          paddingTop: 25,
          paddingLeft: 25,
          paddingRight: 25
        },
        
        table: {
          marginLeft: -5, 
          borderTopStyle: "solid" 
        },

        table_description_cell: {
          width: "50%"
        },

        table_item_cell: {
          width: "25%"
        },

        totals_table: { 
          width: 200, 
          float: "right", 
          paddingRight: 0,
          borderTopStyle: "solid",
          marginTop: 10
        }
      };
    }
  }

  public async load_data() {
    this.setState({
      invoice_is_loading: true
    });

    const accessCodeValid = await Guest_Order_Flow.validate_access_code(this.access_code);
    if(!accessCodeValid) {
      this.setState({
        access_code_valid:  false,
        invoice_is_loading: false
      });
    }
    else {
      try {
        const flowOrderData = await Guest_Order_Flow.get_by_access_code(this.access_code) as any;
        let product_details = {
          upfront_cost: flowOrderData.website_order_flow.upfront_cost,
          monthly_cost: flowOrderData.website_order_flow.monthly_cost,
        };

        // get the stripe client secret for 3d secure payments...
        // const stripeClientSecret = await Guest_Order_Flow.create_payment_setup_intent_client_id(this.access_code);
        this.setState({
          invoice_is_loading: false,

          accessCodeValid: true,
          product_details: product_details,

          date_created: flowOrderData.order_flow.date_created,
          order_type:   flowOrderData.website_order_flow.order_type,
          
          prorated_discount: flowOrderData.website_order_flow.prorate_discount,
          total_due:         flowOrderData.website_order_flow.total_due,
          subtotal_due:      flowOrderData.website_order_flow.subtotal_due,

          billing_details :{
            contact_name:   flowOrderData.account_address.contact_name,
            street_address: flowOrderData.account_address.street_address,
            city:           flowOrderData.account_address.city,
            country:        flowOrderData.account_address.country,
            postal_code:    flowOrderData.account_address.postal_code
          },

          stripe_client_secret:        null,
          stripe_next_action_required: false
        });
      }
      catch(error) {
        // a general unkmown error has been thrown...
        this.setState({
          invoice_is_loading: false,
          access_code_valid: false
        });
      }
    }
  }

  private is_mobile_device(): boolean {
    return window.matchMedia("(max-width: 780px)").matches as boolean;
  }

  private handle_screen_resize(): void {
    this.setState({
      is_mobile: this.is_mobile_device()
    });
  }

  private get_invoice_line_items = () => {
    const state = this.state as any;
    if(state.order_type == "default") {
      return (
        <>
          <TableRow>
            <TableCell>
              Small Business Website
            </TableCell>
            <TableCell style={this.styles.table_description_cell}>
              The upfront design cost of your new website from 121 Digital.
            </TableCell>
            <TableCell>
              1
            </TableCell>
            <TableCell style={{textAlign: "right"}}>
              &pound;{state.product_details.upfront_cost}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Hosting, email and support
            </TableCell>
            <TableCell style={this.styles.table_description_cell}>
              The ongoing cost of your small business website, including hosting, maintenance, email, AI content writer, platform access and support.
            </TableCell>
            <TableCell>
              1
            </TableCell>
            <TableCell style={{textAlign: "right"}}>
              &pound;{state.product_details.monthly_cost}
            </TableCell>
          </TableRow>
        </>
      )
    }
    else if(state.order_type == "hosting") {
      return (
        <>
          <TableRow>
            <TableCell>
              Hosting, email and support
            </TableCell>
            <TableCell style={this.styles.table_description_cell}>
              The ongoing cost of your small business website, including hosting, maintenance, email, AI content writer, platform access and support.
            </TableCell>
            <TableCell>
              1
            </TableCell>
            <TableCell style={{textAlign: "right"}}>
              &pound;{state.product_details.monthly_cost}
            </TableCell>
          </TableRow>
        </>
      )
    }
  }

  public componentDidMount(): void {
    // validate the URL parameters...
    const currentUrl = window.location.href;
    // Find the part after '/order/'
    const orderAccessCode = currentUrl.split('/order/')[1];
    this.access_code = orderAccessCode;

    if (typeof this.access_code !== 'undefined') {
      this.setState({
        access_code_valid: true
      });

      // load the data...
      this.load_data();
    } else {
      // we do not have an access code, so we will display an invalid error....
      this.setState({
        access_code_valid: false
      });
    }
  }

  // private on_payment_submit = async (has_completed: boolean, pm_id?: string) => {
  //   try {
  //     if(has_completed && pm_id) {
  //       const state = this.state as any;
  
  //       // display the processing payment dialog...
  //       this.setState({
  //         payment_is_processing: true
  //       });

  //       // MOVED: This functionality is now contained within the payment dialog...
  //       // const result = await Guest_Order_Flow.pay_guest_order_flow(this.access_code,"James G",pm_id);

  //     //  if(result) {
  //     //   this.setState({
  //     //     display_payment_dialog: false,
  //     //     payment_is_complete: true,
  //     //     payment_is_processing: false
  //     //   });
  //     //  }
  //     //  else {
  //     //   this.setState({
  //     //     payment_is_error: true,
  //     //     payment_is_processing: false
  //     //   });
  //     //   // alert("");
  //     //  }
  //     }
  //     else {
  //       this.setState({
  //         display_payment_dialog: false,
  //         payment_is_processing: false
  //       });
  //     }

  //   }
  //   catch(error) {
  //     throw error;
  //   }
  // }

  public render() {
    const state = this.state as any;
    this.generate_styles();

    return (
      <div style={this.styles.wrapper}>
        <GuestPayInvoiceDialog 
          display={state.display_payment_dialog}
          paymentError={false}
          accessCode={this.access_code}
          // stripeClientSecret={state.stripe_client_secret}
          onClose={(has_completed: boolean, pm_id?: string) => {
            if(has_completed) {
              this.setState({
                payment_is_complete: true,
                display_payment_dialog: false
              }); 
            }
            else {
              this.setState({
                display_payment_dialog: false
              });
            }
        }} />

        <Dialog open={state.invoice_is_loading}>
          <DialogSurface>
            <DialogContent style={{minHeight: 250, paddingTop: 70}}>
              <Spinner size='huge' label={"Loading Invoice, Please Wait."} labelPosition='below'/>
              <div style={{textAlign: 'center'}}>
                <Text align='center'>Please do not refresh this page.</Text>
              </div>
            </DialogContent>
          </DialogSurface>
        </Dialog>

        <Dialog open={state.payment_is_processing}>
          <DialogSurface>
            <DialogContent style={{minHeight: 250, paddingTop: 70}}>
              <Spinner size='huge' label={"Processing Payment, Please Wait."} labelPosition='below'/>
              <div style={{textAlign: 'center'}}>
                <Text align='center'>Please do not refresh this page.</Text>
              </div>
            </DialogContent>
          </DialogSurface>
        </Dialog>

        <Dialog open={state.payment_is_error} >
          <DialogSurface>
            <DialogTitle>Could not take payment</DialogTitle>
            <br />
            <DialogContent>
              <Text>We could not process your payment as your payment method was declined. Please check your payment method and try again. If you think there is an issue, please contact 121 Digital customer support. </Text>
              
              <br /><br />
              <Button onClick={() => {
                this.setState({payment_is_error: false});
              }}>Close</Button>
            </DialogContent>
          </DialogSurface>
        </Dialog>

        <div style={this.styles.invoice_view}>
          <div style={this.styles.header}>

            <div style={this.styles.logo_wrapper}>
              <BrandLogoSvg style={this.styles.logo} height={70} />
            </div>

            {!state.access_code_valid || state.payment_is_complete? (
              <>
                {state.payment_is_complete? (
                  <div style={this.styles.header_buttons}>
                    <Button size='large' style={{float: "right" }}>Account Login</Button>
                  </div>
                ): (<></>)}
              </>
            ): (
              <div style={this.styles.header_buttons}>
                <Button style={this.styles.top_pay_button} onClick={() => {
                  this.setState({display_payment_dialog: true});
                }} appearance='primary' size='large'>
                  Pay Online
                  <ArrowRight20Regular style={{ marginLeft: 5 }} />
                </Button>
                <Button size='large' style={{ marginRight: 15, float: "right" }}>Account Login</Button>
              </div>
            )}
          </div>

          {state.access_code_valid ? (
            <div style={this.styles.invoice_header}>

            {state.payment_is_complete? (
                <>
                  <MessageBar intent='success'>
                    <MessageBarBody>
                      Payment has been received in full, your service is now active.
                    </MessageBarBody>
                    <MessageBarActions>
                      <Button
                        appearance='outline'
                        onClick={() => window.location.assign("https://console.121domains.co.uk")}
                      >Account Login</Button>
                    </MessageBarActions>
                  </MessageBar>
                  <br />
                </>
              ): (<></>)}

              <div>
                <h2>New website order confirmation</h2>
                <span>
                  Your invoice from 121 Digital for your new website. This invoice includes a breakdown of the
                  upfront fee for design (if applicable) and the recurring monthly charge for hosting, maintenance and
                  email. Upon complenion of this process, we will configure an automated monthly charge of
                  &pound;{state.product_details.monthly_cost} to be collected on the 1st day of each month. Since this is
                  the first invoice of the agreement, you will be discounted a prorated value of 
                  &pound;{state.prorated_discount} from the invoice total. A copy of this invoice and payment receipt will be emailed to you for your records.
                </span>
              </div>
              <br /><br />
              <div style={this.styles.invoice_details}>
                <div>
                  <b>Invoice to be paid by:</b> <br />
                  <span>{state.billing_details.contact_name}</span> <br />
                  {state.billing_details.street_address} <br />
                  {state.billing_details.city}, {state.billing_details.country} <br />
                  {state.billing_details.postal_code}
                  <br /><br /><br />
                  <u>Invoice Dates</u> <br />
                  Invoice Created {state.date_created} <br />
                  {/* Invoice due by: <span style={{ marginLeft: 1 }}>01/01/2024</span> */}
                </div>
                <div>
                  <b>Invoice payable to:</b> <br />
                  <span>121 Digital Services Limited (15370776)</span> <br />
                  30 Stanton Road <br />
                  Sandiacre, Nottingham <br />
                  United Kingdom, <br />
                  PO8 0BT
                  <br /><br /><br />
                  <u>Invoice Status</u> <br />

                  {!state.payment_is_complete? (
                    <span>This invoice is unpaid, please make payment <br />by debit or credit card.</span>
                  ): (
                    <div style={{width: 200}}>Invoice has been paid in full.</div>
                  )}
                </div>

                <br /><br /><br />
              </div>

              <b>Invoice billing items</b>
              <Table style={this.styles.table}>
                <TableHeader style={this.styles.table_header}>
                  <TableCell style={this.styles.table_item_cell}>Item name</TableCell>
                  <TableCell style={this.styles.table_description_cell}>Item description</TableCell>
                  <TableCell style={{ width: "10%" }}>Quantity</TableCell>
                  <TableCell style={{width: "15%", textAlign: "right"}}>Total</TableCell>
                </TableHeader>

                {this.get_invoice_line_items()}
              </Table>
              
              <div style={this.styles.totals_table}>
                <Table>
                  <TableRow>
                    <TableCell style={{ width: '70%', fontWeight: 'bold' }}>Subtotal</TableCell>
                    <TableCell style={{ width: '30%', textAlign: 'right' }}>&pound;{state.subtotal_due}</TableCell> {/* Updated amount */}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '70%', fontWeight: 'bold' }}>
                      <small>Pro-Rate Adjustment</small></TableCell>
                    <TableCell style={{ width: '30%', textAlign: 'right' }}>-&pound;{state.prorated_discount}</TableCell> {/* Updated amount */}
                  </TableRow>
                  {/* <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>VAT (20%)</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>&pound;N/A</TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                      {state.payment_is_complete? (
                        <Text weight='bold' color='success'>Total Received</Text>
                      ): (
                        <span>Total due today</span>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right', fontSize: '1.1em', fontWeight: 'bold' }}>&pound;{state.total_due}</TableCell> {/* Updated total */}
                  </TableRow>
                </Table>
                <br />

                {state.payment_is_complete? (
                  <Button style={{float: "right"}} onClick={() => {
                    window.location.assign('https://console.121domains.co.uk');
                  }}>Account Login</Button>
                ): (
                  <Button onClick={() => {
                    this.setState({display_payment_dialog: true});
                  }} style={{ float: "right" }} appearance='primary'>
                    Pay Online
                    <ArrowRight16Regular style={{ marginLeft: 5 }} />
                  </Button>
                )}
              </div>

              <Spacer size="300px" />
            </div>
          ) : (
            <GuestPayInvoiceInvalid />
          )}
        </div>
      </div>
    );
  }
}
