import { Menu, MenuItem, MenuList, Toolbar, ToolbarButton, ToolbarDivider, Tree, TreeItem, makeStyles } from '@fluentui/react-components';
import React from 'react';
import {
  Home24Regular,
  People24Regular,
  Settings24Regular,
  SignOut24Regular,
} from '@fluentui/react-icons';

export default class WebsiteEditorSidebar extends React.Component {

  private styles: any;

  public constructor(props: any) {
    super(props);

    this.styles = {
      iconBar: {
        width: '200px', // Width to accommodate text
        height: '100vh',
        backgroundColor: '#f3f2f1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align items to the left
        padding: '10px 0',
      },
      iconButton: {
        margin: '10px 0',
        display: 'flex',
        alignItems: 'center', // Align icon and text horizontally
        justifyContent: 'flex-start', // Align content to the left
        padding: '0 15px', // Add some padding for better appearance
        width: '100%',
        paddingTop: 15,
      },
      iconText: {
        marginLeft: '10px', // Space between icon and text
      },
    };
  }

  public render() {
    return (
      <Toolbar vertical style={this.styles.iconBar}>
        <ToolbarButton
          style={{...this.styles.iconButton, paddingTop: 0}}
          icon={<Home24Regular />}
          aria-label="Home"
        >
          <span style={this.styles.iconText}>Home</span>
        </ToolbarButton>
        <ToolbarButton
          style={this.styles.iconButton}
          icon={<People24Regular />}
          aria-label="People"
        >
          <span style={this.styles.iconText}>People</span>
        </ToolbarButton>
        <ToolbarButton
          style={this.styles.iconButton}
          icon={<Settings24Regular />}
          aria-label="Settings"
        >
          <span style={this.styles.iconText}>Settings</span>
        </ToolbarButton>
        <ToolbarDivider />
        <ToolbarButton
          style={this.styles.iconButton}
          icon={<SignOut24Regular />}
          aria-label="Sign Out"
        >
          <span style={this.styles.iconText}>Sign Out</span>
        </ToolbarButton>
      </Toolbar>
    );
  }

}