import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import backgroundImage from './../../assets/backgrounds/default.jpg';
import { ReactComponent as BrandLogoSvg } from './../../assets/branding/brand.svg';
import { Button, Table, TableCell, TableHeader, TableRow } from '@fluentui/react-components';
import { ArrowRight16Regular, ArrowRight20Regular } from '@fluentui/react-icons';
import Spacer from '../../components/Spacer/Spacer';
import GuestPayInvoiceDialog from '../../components/Guest/GuestPayInvoiceDialog';
import GuestPayInvoiceInvalid from '../../components/Guest/GuestPayInvoiceInvalid/GuestPayInvoiceInvalid'; // Corrected typo

export default class GuestWebsiteServiceStartView extends React.Component {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // define default state...
    this.state = {
      access_code_valid: false,
      display_payment_dialog: false,
      is_mobile: this.is_mobile_device(),
    };

    // default styles init...
    this.styles = {};

    this.handle_screen_resize();
    // Listen for changes in viewport size
    window.addEventListener('resize', () => {
      this.handle_screen_resize();
    });
  }

  private is_mobile_device(): boolean {
    return window.matchMedia("(max-width: 780px)").matches as boolean;
  }

  private handle_screen_resize(): void {
    this.setState({
      is_mobile: this.is_mobile_device()
    });
  }

  public generate_styles(): void {

    // update parts for mobile...
    const state = this.state as any;
    console.log(state);

    if(state.is_mobile) {
      this.styles = {
        wrapper: {
          width: '100vw',
          // height: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          justifyContent: 'flex-end',
          zIndex: 999999
        },
  
        invoice_view: {
          width: "100%",
          minHeight: 650,
          backgroundColor: "white",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 0,
          borderRadius: 0
        },
  
        header: {
          paddingTop: 25,
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20
        },
        logo: {
          marginLeft: -340,
          marginTop: 15
        },
  
        invoice_header: {
          paddingTop: 25,
          paddingLeft: 25,
          paddingRight: 25
        },

        header_buttons: {
          marginTop: 25
        },

        table: {
          display: "none"
        }
      };
    }
    else {
      this.styles = {
        wrapper: {
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          justifyContent: 'flex-end',
          zIndex: 999999
        },
  
        invoice_view: {
          width: 1000,
          minHeight: 650,
          backgroundColor: "white",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          borderRadius: 4
        },
  
        header: {
          paddingTop: 25,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20
        },
        logo: {
          marginLeft: -340
        },
  
        invoice_header: {
          paddingTop: 25,
          paddingLeft: 25,
          paddingRight: 25
        },
        
        header_buttons: {},

        table: {
          marginLeft: -5, 
          borderTopStyle: "solid" 
        }
      };
    }
  }

  public componentDidMount(): void {
    // validate the URL parameters...
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.has("access_code")) {
      const accessCode = params.get("access_code");
      this.setState({
        access_code_valid: true
      });
    } else {
      // we do not have an access code, so we will display an invalid error....
      this.setState({
        access_code_valid: false
      });
    }
  }

  public render() {
    const state = this.state as any;
    this.generate_styles();

    return (
      <div style={this.styles.wrapper}>
        <GuestPayInvoiceDialog display={state.display_payment_dialog} paymentError={false} onClose={() => {
          this.setState({display_payment_dialog: false});
        }} />

        <div style={this.styles.invoice_view}>
          <div style={this.styles.header}>
            <BrandLogoSvg style={this.styles.logo} height={70} />

            {!state.access_code_valid? (<></>): (
              <div style={this.styles.header_buttons}>
                <Button size='large' style={{ marginRight: 15 }}>Account Login</Button>
                <Button onClick={() => {
                  this.setState({display_payment_dialog: true});
                }} appearance='primary' size='large'>
                  Pay as guest
                  <ArrowRight20Regular style={{ marginLeft: 5 }} />
                </Button>
              </div>
            )}
          </div>

          {state.access_code_valid ? (
            <div style={this.styles.invoice_header}>
              <div>
                <h2>Make your new website live</h2>
                <span>
                  This proccess will configure the monthly ongoing costs that are required
                  to make your website live. All ongoing costs are billed on the 1st day of
                  every month, so your balance due today is pro-rated to get started. Below is
                  your initial invoice, adjusted for pro-rate.
                </span>
              </div>
              <br /><br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <b>Invoice to be paid by:</b> <br />
                  <span>Mr James Gibbons, 121 Digital Services Limited</span> <br />
                  30 Stanton Road <br />
                  Sandiacre, Nottingham <br />
                  United Kingdom, <br />
                  PO8 0BT
                  <br /><br /><br />
                  <u>Invoice Dates</u> <br />
                  Invoice Created 01/01/2024 <br />
                  Invoice due by: <span style={{ marginLeft: 1 }}>01/01/2024</span>
                </div>
                <div>
                  <b>Invoice payable to:</b> <br />
                  <span>121 Digital Services Limited (15370776)</span> <br />
                  30 Stanton Road <br />
                  Sandiacre, Nottingham <br />
                  United Kingdom, <br />
                  PO8 0BT
                  <br /><br /><br />
                  <u>Invoice Status</u> <br />
                  This invoice is unpaid, please make payment <br />by debit or credit card.
                </div>
              </div>

              <br /><br /><br />
              <b>Invoice billing items</b>
              <Table style={this.styles.table}>
                <TableHeader style={this.styles.table_header}>
                  <TableCell style={{ width: "25%" }}>Item name</TableCell>
                  <TableCell style={{ width: "50%" }}>Item description</TableCell>
                  <TableCell style={{ width: "10%" }}>Item quantity</TableCell>
                  <TableCell style={{ width: "15%" }}>Item unit price</TableCell>
                </TableHeader>
                <TableRow>
                  <TableCell>
                    Small Business Website
                  </TableCell>
                  <TableCell>
                    The ongoing cost of your small business website, including hosting, maintenance, email, AI content writer, platform access and support.
                  </TableCell>
                  <TableCell>
                    1
                  </TableCell>
                  <TableCell>
                    &pound;49.99
                  </TableCell>
                </TableRow>
              </Table>
              
              <div style={{ width: 200, float: "right", paddingRight: 25 }}>
                <Table>
                  <TableRow>
                    <TableCell style={{ width: '70%', fontWeight: 'bold' }}>Subtotal</TableCell>
                    <TableCell style={{ width: '30%', textAlign: 'right' }}>&pound;49.00</TableCell> {/* Updated amount */}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: '70%', fontWeight: 'bold' }}>
                      <small>Pro-Rate Adjustment</small></TableCell>
                    <TableCell style={{ width: '30%', textAlign: 'right' }}>&pound;13.00</TableCell> {/* Updated amount */}
                  </TableRow>
                  {/* <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>VAT (20%)</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>&pound;N/A</TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Total due today</TableCell>
                    <TableCell style={{ textAlign: 'right', fontSize: '1.1em', fontWeight: 'bold' }}>&pound;499.00</TableCell> {/* Updated total */}
                  </TableRow>
                </Table>
                <br />
                <Button onClick={() => {
                  this.setState({display_payment_dialog: true});
                }} style={{ float: "right" }} appearance='primary'>
                  Pay as guest
                  <ArrowRight16Regular style={{ marginLeft: 5 }} />
                </Button>
              </div>

              <Spacer size="300px" />
            </div>
          ) : (
            <GuestPayInvoiceInvalid />
          )}
        </div>
      </div>
    );
  }
}
