import { Button, Text } from '@fluentui/react-components';
import React from 'react';
import { Info48Regular, Warning48Regular } from '@fluentui/react-icons'; // Assuming you are using this icon

export default class GuestPayInvoiceInvalid extends React.Component {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // define styles..
    this.styles = {
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh', // Full viewport height
        height: 400,
        textAlign: 'center'
      },
      icon: {
        fontSize: '48px', // Adjust size as needed
        // marginBottom: '20px'
      },
      message: {
        fontSize: '18px'
      }
    };
  }

  public render() {
    return (
      <div style={this.styles.wrapper}>
        <div style={{marginTop: 55}}>
          <Warning48Regular style={this.styles.icon} />
          <h2>Invalid Invoice</h2>
          <Text style={this.styles.message}>This invoice is not available or your access code is invalid.</Text>

          <br /><br />
          <Button onClick={() => {
            window.location.assign("https://console.121domains.co.uk/auth/login");
          }} size='large'>Account Login</Button>
          <Button onClick={() => {
            window.location.assign("https://121digital.co.uk")
          }} style={{marginLeft: 15}} size='large'>www.121digital.co.uk</Button>
        </div>
      </div>
    );
  }
}
