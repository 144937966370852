import React from 'react';
import SafeArea from '../../components/SafeArea/SafeArea';
import backgroundImage from './../../assets/backgrounds/default.jpg';
import { ReactComponent as BrandLogoSvg } from './../../assets/branding/brand.svg';
import { Button, Table, TableCell, TableHeader, TableRow } from '@fluentui/react-components';
import { ArrowRight16Regular, ArrowRight20Regular } from '@fluentui/react-icons';
import Spacer from '../../components/Spacer/Spacer';
import GuestPayInvoiceDialog from '../../components/Guest/GuestPayInvoiceDialog';
import GuestPayInvoiceInvalid from '../../components/Guest/GuestPayInvoiceInvalid/GuestPayInvoiceInvalid'; // Corrected typo

export default class GuestViewInvoiceView extends React.Component {

  private styles: any = {};

  public constructor(props: any) {
    super(props);

    // define default state...
    this.state = {
      access_code_valid: false
    };

    // define view styles...
    this.styles = {
      wrapper: {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        overflow: 'hidden',
        justifyContent: 'flex-end',
        zIndex: 999999
      },

      invoice_view: {
        width: 1000,
        minHeight: 650,
        backgroundColor: "white",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 100,
        borderRadius: 4
      },

      header: {
        paddingTop: 25,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20
      },
      logo: {
        marginLeft: -340
      },

      invoice_header: {
        paddingTop: 25,
        paddingLeft: 25,
        paddingRight: 25
      }
    };
  }

  public componentDidMount(): void {
    // validate the URL parameters...
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.has("access_code")) {
      const accessCode = params.get("access_code");
      this.setState({
        access_code_valid: true
      });
    } else {
      // we do not have an access code, so we will display an invalid error....
      this.setState({
        access_code_valid: false
      });
    }
  }

  public render() {
    const state = this.state as any;

    return (
      <div style={this.styles.wrapper}>
        <GuestPayInvoiceDialog display={false} onClose={() => {}} paymentError={false} />

        <div style={this.styles.invoice_view}>
          <div style={this.styles.header}>
            <BrandLogoSvg style={this.styles.logo} height={70} />

            {!state.access_code_valid? (<></>): (
              <div>
                <Button size='large' style={{ marginRight: 15 }}>Account Login</Button>
                <Button appearance='primary' size='large'>
                  Pay as guest
                  <ArrowRight20Regular style={{ marginLeft: 5 }} />
                </Button>
              </div>
            )}
          </div>

          {state.access_code_valid ? (
            <div style={this.styles.invoice_header}>
              <div>
                <h2>View your invoice online</h2>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <b>Invoice to be paid by:</b> <br />
                  <span>Mr James Gibbons, 121 Digital Services Limited</span> <br />
                  30 Stanton Road <br />
                  Sandiacre, Nottingham <br />
                  United Kingdom, <br />
                  PO8 0BT
                  <br /><br /><br />
                  <u>Invoice Dates</u> <br />
                  Invoice Created 01/01/2024 <br />
                  Invoice due by: <span style={{ marginLeft: 1 }}>01/01/2024</span>
                </div>
                <div>
                  <b>Invoice payable to:</b> <br />
                  <span>121 Digital Services Limited (15370776)</span> <br />
                  30 Stanton Road <br />
                  Sandiacre, Nottingham <br />
                  United Kingdom, <br />
                  PO8 0BT
                  <br /><br /><br />
                  <u>Invoice Status</u> <br />
                  This invoice is unpaid, please make payment <br />by debit or credit card.
                </div>
              </div>

              <br /><br /><br />
              <b>Invoice billing items</b>
              <Table style={{ marginLeft: -5, borderTopStyle: "solid" }}>
                <TableHeader>
                  <TableCell style={{ width: "25%" }}>Item name</TableCell>
                  <TableCell style={{ width: "50%" }}>Item description</TableCell>
                  <TableCell style={{ width: "10%" }}>Item quantity</TableCell>
                  <TableCell style={{ width: "15%" }}>Item unit price</TableCell>
                </TableHeader>
                <TableRow>
                  <TableCell>
                    Small Business Web Design
                  </TableCell>
                  <TableCell>
                    Initial design cost for your managed small business website.
                  </TableCell>
                  <TableCell>
                    1
                  </TableCell>
                  <TableCell>
                    &pound;499.00
                  </TableCell>
                </TableRow>
              </Table>
              
              <div style={{ width: 200, float: "right", paddingRight: 25 }}>
                <Table>
                  <TableRow>
                    <TableCell style={{ width: '70%', fontWeight: 'bold' }}>Subtotal</TableCell>
                    <TableCell style={{ width: '30%', textAlign: 'right' }}>&pound;499.00</TableCell> {/* Updated amount */}
                  </TableRow>
                  {/* <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>VAT (20%)</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>&pound;N/A</TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Total</TableCell>
                    <TableCell style={{ textAlign: 'right', fontSize: '1.1em', fontWeight: 'bold' }}>&pound;499.00</TableCell> {/* Updated total */}
                  </TableRow>
                </Table>
                <br />
                <Button style={{ float: "right" }} appearance='primary'>
                  Pay as guest
                  <ArrowRight16Regular style={{ marginLeft: 5 }} />
                </Button>
              </div>

              <Spacer size="300px" />
            </div>
          ) : (
            <GuestPayInvoiceInvalid />
          )}
        </div>
      </div>
    );
  }
}
